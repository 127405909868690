import type { ProspectMeetingsMetricQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import { useState } from "react";
import MetricGauge from "~/components/metric-gauge";
import EditGoal from "~/components/scoreboard/edit-goal";

type Props = {
  quarter: string;
  name?: string;
  userId?: string;
};

export default function ProspectMeetings({
  name = "Prospect Meetings",
  quarter,
  userId
}: Props) {
  const [editing, setEditing] = useState(false);
  const {
    prospectMeetingData: { dynamicMetrics, goal }
  } = useLoaderData<{ prospectMeetingData: ProspectMeetingsMetricQuery }>();

  const count = dynamicMetrics.reduce((acc, item) => acc + item.count!, 0);
  const goalValue = goal ? parseFloat(goal.value) : 0;

  return (
    <div>
      {editing && (
        <EditGoal
          name={name}
          quarter={quarter}
          goal={goalValue}
          onClose={() => setEditing(false)}
        />
      )}
      <MetricGauge
        onClick={() => setEditing(true)}
        label={name}
        scaleToQuarter={quarter}
        link={`/sales/activity-log?quarter=${quarter}&type=Prospect%20Meeting&type=Prospect+Meeting+%28Expert%29&user=${userId || "ALL"}`}
        value={count}
        goal={goalValue}
      />
    </div>
  );
}
